const uat_homeConfig = {
  BASE_PATH: 'https://uat.sadhguru.org',
  FACADE_BASE_PATH: 'https://uat-iso-facade.sadhguru.org',
  YOUTH_OFFER_PATH: 'https://staging.innerengineering.com',
  SCHEDULE_API_PATH: 'https://api.ishafoundation.org/scheduleApi/api.php',
  SCHEDULE_DATA_API_PATH:
    'https://apiuat.ishafoundation.org/scheduleApi/data.php?task=preload',
  PROGRAMS_LIST_API_PATH:
    'https://apiuat.ishafoundation.org/scheduleApi/data.php?task=list',
  PROGRAMS_API_PATH: 'https://apiuat.ishafoundation.org/ishaapi/api/v1',
  IEO_SPT_URL:
    'https://staging.innerengineering.com/api/v3.0/course/support-details',
  HATHA_YOGA_PROGRAM_API_PATH: 'https://iso-hh-api.isha.in/iso_programs',
  FIND_A_TEACHER_API_PATH: 'https://iso-hh-api.isha.in/iso/teachers/finder/',
  HATHA_YOGA_REGISTRATION_FORM:
    'https://hys.isha.in/hys_portal/application/rest_api/user_register.php',
  IDY_REGISTRATION_FORM:
    'http://uat-iso-facade.sadhguru.org/content/subscription/add',
  CSR_PARTNERWITHUS_FORM:
    'https://uat-iso-facade.sadhguru.org/content/subscription/add',
  HYTT_TALKTOALUMNI_FORM:
    'https://uat-iso-facade.sadhguru.org/content/subscription/add',
  SADHANAPADA_WEBINAR_REGISTRATION_FORM:
    'https://uat-iso-facade.sadhguru.org/content/bigmarker/webinar/register/iso',
  DONATE_API: 'https://apiuat.ishafoundation.org/ishaapi/api/v1/donate/offline',
  GOY_PRICE_API:
    'https://uat-iso-facade.sadhguru.org/content/goy/get-program-info',
  SANNIDHI_PROGRAM_API:
    'https://uat-iso-facade.sadhguru.org/content/get-program-info?type=sannidhi',
  FYD_Program_API:
    'https://staging.innerengineering.com/schedule-api/v2.0/listing?page=1&category=any-category&daterange=any-date&fee=any-fee&type=any-type&invCategory=false&centerId=25&pgmTypeClassification=event&sortField=date&country=US&pgmTypeId=4',
  FREE_YOGA_DAY_REGISTRATION_FORM:
    'https://qa-prs.innerengineering.com/ieo/newadmin/api/events/rsvp',
  MMM_PROGRAM_API:
    'https://uat-iso-facade.sadhguru.org/content/get-program-info?type=mmm',
  PBK_PROGRAM_API:
    'https://uat-iso-facade.sadhguru.org/content/goy/get-program-info?type=pbk',
  YANTRAINLINEFORM:
    'https://preprod-inlineform-online.sadhguru.org/inline-form.bundle.js',
  ILAINLINEFORM:
    'https://preprod-inlineform-online.sadhguru.org/inline-form.bundle.js',
  IEO_PRG_URL:
    'https://qa-prs.innerengineering.com/ieo/newadmin/api/ieo/ieoPgms',
  NEXT_PUBLIC_USPRS_API_URL:
    'https://qa-prs.innerengineering.com/ieo/newadmin/api',
  NEXT_PUBLIC_GEO_API_URL:
    'https://qa-innerengineering.sadhguru.org/edgeapi/region',
  NEXT_PUBLIC_IEO_VALIDATE_EMAIL_URL:
    'https://qa-prs.innerengineering.com/ieo/newadmin/api/ieo/userCheck',
  NEXT_PUBLIC_GPMS_URL:
    'https://uat1-payment.sadhguru.org/api/ieprs/payment-request',
  NEXT_PUBLIC_IEO_LOGIN_URL: 'https://staging.innerengineering.com/LANG/login',
  IEO_PRE_SIGNED_UPLOAD_URL:
    'https://staging.innerengineering.com/register-api/v2.0/registration/upload-file/pre-signed-url',
  MSR_VIDEO_BUCKET_URL: 'https://dz8eesk2qtdx3.cloudfront.net',
  YOGA_SANDHYA_IYC_LINK: 'https://preprod-online.sadhguru.org/event-register?event=yoga-sandhya-at-isha-yoga-center-917',
  YOGA_SANDHYA_SSB_LINK: 'https://online.sadhguru.org/event-register?event=yoga-sandhya-901',
  EOE_EVENT: 'https://preprod-facade-online.sadhguru.org',
  EOE_EVENT_DOMAIN: 'https://preprod-frontend-online.sadhguru.org',
  IDY_PUSH: 'https://dev-api-ishangam.isha.in/rl_initiatives',
  PUSH_LEADS: 'https://dev-api-ishangam.isha.in',
  EMAIL_LOGIN_URL: 'https://staging.innerengineering.com/en/7-steps/public/landing?choose_login_option=password&login_hint=[EMAIL]&disable_autologin=1',
  MY_PROFILE_URL: 'https://uat-sso.isha.in',
  COOKIE_DOMAIN: '.sadhguru.org',
  ENV_NAME: 'uat',
  IS_LOG: true,
  USE_LOCAL_URL: false,
  BUILD_URLS: {
    HOME: {
      lvl0: [],
      enabled: false,
    },
    SADHGURU: {
      lvl0: [],
      wisdom: {
        L1: [],
        L2: [],
      },
      enabled: false,
    },
    WISDOM: {
      /* lvl0: ['/en/wisdom/'], */
      wisdom: {
        L1: [],
        L2: [],
      },
      enabled: false,
    },
    HEALTH: {
      lvl0: ['/en/health'],
      health: {
        L1: [],
        L2: [],
        L3: [],
      },
      enabled: false,
    },
    CENTER: {
      lvl0: ['/en/center'],
      health: {
        L1: [],
        L2: [],
        L3: [],
      },
      enabled: false,
    },
    YM: {
      lvl0: [],
      'yoga&meditation': {
        L1: [],
        L2: [],
        L3: [],
      },
      enabled: false,
    },
    EVENTS: {
      lvl0: [],
      events: {
        L1: [],
        L2: [],
        L3: [],
      },
      enabled: false,
    },
    OTHERS: {
      lvl0: [],
      other_pages: {
        L1: [],
        L2: [],
        L3: [],
      },
      enabled: false,
    },
    DONATION: {
      lvl0: [],
      other_pages: {
        L1: [],
        L2: [],
        L3: [],
      },
      enabled: false,
    },
    VOLUNTEER: {
      lvl0: [],
      other_pages: {
        L1: [],
        L2: [],
        L3: [],
      },
      enabled: false,
    },
    NEWS: {
      lvl0: [],
      other_pages: {
        L1: [],
        L2: [],
        L3: [],
      },
      enabled: false,
    },
    OUTREACH: {
      lvl0: [],
      other_pages: {
        L1: [],
        L2: [],
        L3: [],
      },
      enabled: false,
    },
    LANDING_PAGES: {
      // will build these landing pages if it is set to true
      YantraCeremony: false,
      SadhguruExclusive: false,
      SadhguruExclusiveRenew: false,
      SadhguruExclusiveEvent: false,
      SadhguruExclusiveTrial: false,
      SadhguruSannidhi: false,
      ShivangaLanding: false,
      Sadhanapada: false,
      GOY: false,
      IEO: false,
      MYY: false,
      Nagapratishta: false,
      RudrakshaDiksha: false,
      SubscribeFailure: true,
      SubscribeConfirm: true,
    },
  },
  USA_PROG_API:
    'https://staging.innerengineering.com/schedule-assets/js/embed-v1.0.js',
  CCAVENUE: {
    MERCHANT_ID: 213896,
    ACCESS_CODE: 'AVFL03IH93BW19LFWB',
    WORKING_KEY: 'DCE5CF1551E021582E43DD88B078364F',
    DOMAIN_NAME: 'https://test.ccavenue.com',
  },
  RECAPTCHA_KEY: '6LcjOCwaAAAAAE9OCSYsf2JJYsWjYr7iWAof6B4_',
};

export default uat_homeConfig;
