/* Built In Imports */
/* External Imports */
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button, FormLabel, Stack, Switch, Text } from '@chakra-ui/react';
import { FaShoppingBag } from 'react-icons/fa';
import { TbX } from 'react-icons/tb';
/* Components */
/* Styles */
/* Service */
// import { HiOutlineSpeakerWave } from 'react-icons/hi';
// import { RxSpeakerLoud } from 'react-icons/ri';
/**
 * Renders LbArrowIcons
 *
 * @param root0
 * @param root0.bArrow
 * @param bArrow
 * @returns {ReactElement} - LbArrowIcons
 */
const LbArrowIcons = ({ bArrow }) => {
  return (
    <>
      <Button
        variant="secondary"
        p="0"
        // bgColor="primary.main"
        // _hover={{
        //   bgColor: 'primary.main',
        // }}
        rounded={0}
        height="48px"
        cursor="pointer"
        border={0}
        width="48px"
        boxShadow="0px 28.8px 53.6px rgba(0, 0, 0, 0.2), 0px 18.6667px 31.3907px rgba(0, 0, 0, 0.151852), 0px 11.0933px 17.0726px rgba(0, 0, 0, 0.121481), 0px 5.76px 8.71px rgba(0, 0, 0, 0.1), 0px 2.34667px 4.36741px rgba(0, 0, 0, 0.0785185), 0px 0.533333px 2.10926px rgba(0, 0, 0, 0.0481481)"
        fontWeight="bold"
        fontSize="16px"
        outline="none"
      >
        {bArrow || <ArrowBackIcon />}
      </Button>
    </>
  );
};
/**
 *
 * @param root0
 * @param root0.arrowIcon
 * @param root0.bgColor
 * @param root0.color
 */
const StatArrow = ({ arrowIcon, bgColor, color }) => {
  return (
    <>
      <Button
        border={`2.666667px solid ${color || 'black'}`}
        borderRadius="100%"
        bgColor={bgColor || 'transparent'}
        textAlign="center"
        width="33.33px"
        cursor="pointer"
        height="33.33px"
        color={color || 'black'}
        _hover={{
          bgColor: 'transparent',
          border: '2.666667px solid #F6A15E',
          color: '#F6A15E',
        }}
      >
        {arrowIcon || <ArrowBackIcon />}
      </Button>
    </>
  );
};

/**
 *
 * @param content
 * @param bgColor
 * @param color
 * @param clipPath
 * @param variant
 * @param onClick
 */

const FlagButton = ({
  content,
  bgColor,
  color,
  clipPath,
  variant,
  onClick,
  height,
  width,
  ...position
}) => {
  return (
    <Button
      onClick={onClick}
      variant={variant ? variant : 'primary'}
      background={bgColor || 'primary.main'}
      clipPath={clipPath || 'polygon(100% 0, 0% 100%, 100% 100%)'}
      border="none"
      width={width || '80px'}
      textAlign="right"
      cursor="pointer"
      // color={color || 'white'}
      p={0}
      alignItems="flex-end"
      height={height || '90px'}
      pos="relative"
      fontSize="18px"
    >
      <Box
        as="span"
        color={color || 'white'}
        fontFamily="sans-serif"
        {...position}
      >
        {content || <TbX />}
      </Box>
    </Button>
  );
};

const IconButton = ({ color, content }) => {
  return (
    <>
      <Button
        variant="ghost"
        color="brand.primary.main"
        _hover={{
          color: color || 'red',
        }}
      >
        <Box as="span">{content || <FaShoppingBag />}</Box>
      </Button>
    </>
  );
};

/**
 *
 */
const SwitchIcon = () => {
  return (
    <>
      <Stack direction="row">
        <FormLabel htmlFor="first">Toggle it</FormLabel>
        <Switch name="first" id="first" colorScheme="red" />
        <Switch colorScheme="teal" size="lg" className="first" />
        <style jsx>
          {`
            .css-2leizt {
              border: 1px solid #b7987e;
              outline: 1px solid #b7987e;
            }
            .css-2leizt[aria-checked='true'],
            .css-2leizt[data-checked] {
              border: 1px solid #b7987e;
              outline: 1px solid #b7987e;
            }
          `}
        </style>
      </Stack>
    </>
  );
};

const SpeakerIcon = () => {
  return (
    <Button rounded="full" fontSize="25px">
      <Text>
        {/* <HiOutlineSpeakerWave /> */}
        {/* <RxSpeakerLoud /> */}
      </Text>
    </Button>
  );
};

export { SpeakerIcon, SwitchIcon, IconButton, FlagButton, StatArrow };
export default LbArrowIcons;
