/* Built In Imports */
import React, { useEffect, useRef, useState } from 'react';

/* External Imports */
import { ChakraProvider } from '@chakra-ui/react';
import TagManager from 'react-gtm-module';

/* Internal Imports */
/* Components */
import Layout from '@components/Layout/Layout';
import BuildTimeComment from '@components/Utility/BuildTimeComment';
// import { OptinMonsterEmbed } from '@ieo/Components/OptinMonsterEmbed';
import LbLayout from '@lb/components/Layout/Layout';
import MsrLayout from '@msr/components/Layout/Layout';
import AuthContextProvider from '@store/auth-context';
import { BUILD_TYPE_CONFIG } from '@utils/index';
import { ishaTheme } from '../styles/theme/index';

/* Services */
/* Styles */

let nextBuildType = process?.env?.NEXT_PUBLIC_BUILDTYPE?.toUpperCase();
const { buildType } = BUILD_TYPE_CONFIG;

if (nextBuildType === buildType.LINGABHAIRAVI) {
  await import('../styles/globals-lb.css');
} else if (nextBuildType === buildType.MSR) {
  await import('../styles/globals-msr.css');
} else {
  await import('../styles/globals.css');
}

/**
 * @param Component
 * @param pageProps
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>Oops, there is an error!</h2>
          <button
            type="button"
            onClick={() => this.setState({ hasError: false })}
          >
            Try again?
          </button>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

// export default ErrorBoundary
const ISOApp = ({ Component, pageProps }) => {
  const [blType, setBlType] = useState(nextBuildType);
  const firstUpdate = useRef(true);

  const tagManagerArgs = {
    gtmId: 'GTM-5G2NJ8P',
  };

  useEffect(() => {
    if (
      window?.location.pathname.indexOf(
        '/yoga-meditation/additional-programs/bhairavi-sadhana'
      ) > -1
    ) {
      nextBuildType = buildType.LINGABHAIRAVI;
      setBlType(buildType.LINGABHAIRAVI);
    }

    setTimeout(function () {
      if (firstUpdate.current) {
        if (process.browser) {
          TagManager.initialize(tagManagerArgs);
        }
        firstUpdate.current = false;
      } else {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'Pageview',
          url: window.location.href,
          title: window.document.title,
        });
      }
    }, 1500);
  }, [pageProps]);

  return (
    <AuthContextProvider>
      <ChakraProvider resetCSS theme={ishaTheme}>
        {(process.env.NEXT_PUBLIC_BUILDTYPE === 'LINGABHAIRAVI' ||
          pageProps.menuType === 'lb') && (
            <LbLayout>
              <Component {...pageProps} />
            </LbLayout>
          )}
        {(process.env.NEXT_PUBLIC_BUILDTYPE === 'MSR' ||
          pageProps.menuType === 'msrmainmenu') && (
            <MsrLayout>
              <Component {...pageProps} />
            </MsrLayout>
          )}
        {pageProps.menuType !== 'msrmainmenu' &&
          pageProps.menuType !== 'lb' &&
          process.env.NEXT_PUBLIC_BUILDTYPE !== 'MSR' &&
          process.env.NEXT_PUBLIC_BUILDTYPE !== 'LINGABHAIRAVI' && (
            <Layout>
              <Component {...pageProps} />
            </Layout>
          )}
      </ChakraProvider>
      <BuildTimeComment />
      {/* <OptinMonsterEmbed /> */}
    </AuthContextProvider>
  );
};

export default ISOApp;
