import {
  developmentConfig,
  development_homeConfig,
  localConfig,
  productionConfig,
  production_homeConfig,
  qaConfig,
  stage_homeConfig,
  stage_v2Config,
  stagingConfig,
  uatConfig,
  uat_homeConfig,
} from './config/buildConfigs';

const generalConfig = {
  locales: [
    {
      locale: 'au-en',
      region: 'Australia',
      region_sf: 'au',
      language: 'English',
      language_sf: 'en',
      available_local: 'en_AU',
    },
    {
      locale: 'ca-en',
      region: 'Canada',
      region_sf: 'ca',
      language: 'English',
      language_sf: 'en',
      available_local: 'en_CA',
    },
    {
      locale: 'in-en',
      region: 'India',
      region_sf: 'in',
      language: 'English',
      language_sf: 'en',
      available_local: 'en_IN',
    },
    {
      locale: 'in-hi',
      region: 'India',
      region_sf: 'in',
      language: 'Hindi',
      language_sf: 'hi',
      available_local: 'hi_IN',
    },
    {
      locale: 'in-ta',
      region: 'India',
      region_sf: 'in',
      language: 'Tamil',
      language_sf: 'ta',
      available_local: 'ta_IN',
    },
    {
      locale: 'in-te',
      region: 'India',
      region_sf: 'in',
      language: 'Telugu',
      language_sf: 'te',
      available_local: 'te_IN',
    },
    {
      locale: 'in-kn',
      region: 'India',
      region_sf: 'in',
      language: 'Kannada',
      language_sf: 'kn',
      available_local: 'kn_IN',
    },
    {
      locale: 'in-ml',
      region: 'India',
      region_sf: 'in',
      language: 'Malayalam',
      language_sf: 'ml',
      available_local: 'ml_IN',
    },
    {
      locale: 'in-mr',
      region: 'India',
      region_sf: 'in',
      language: 'Marathi',
      language_sf: 'mr',
      available_local: 'mr_IN',
    },
    {
      locale: 'my-en',
      region: 'Malaysia',
      region_sf: 'my',
      language: 'English',
      language_sf: 'en',
      available_local: 'en_MY',
    },
    {
      locale: 'sg-en',
      region: 'Singapore',
      region_sf: 'sg',
      language: 'English',
      language_sf: 'en',
      available_local: 'en_SG',
    },
    {
      locale: 'uk-en',
      region: 'UK & Europe',
      region_sf: 'uk',
      language: 'English',
      language_sf: 'en',
      available_local: 'en_UK',
    },
    {
      locale: 'us-en',
      region: 'United States',
      region_sf: 'us',
      language: 'English',
      language_sf: 'en',
      available_local: 'en_US',
    },
    /* {
      locale: 'hk-en',
      region: 'Hong Kong',
      region_sf: 'hk',
      language: 'English',
      language_sf: 'en',
      available_local: 'en_HK',
    },
    {
      locale: 'nz-en',
      region: 'New Zealand',
      region_sf: 'nz',
      language: 'English',
      language_sf: 'en',
      available_local: 'en_NZ',
    }, */
    // {
    //   locale: 'eu-en',
    //   region: 'Europe',
    //   region_sf: 'eu',
    //   language: 'English',
    //   language_sf: 'en',
    // },
    {
      locale: 'global-en',
      region: 'Global',
      region_sf: 'global',
      language: 'English',
      language_sf: 'en',
      available_local: 'en',
    },
    {
      locale: 'global-de',
      region: 'Global',
      region_sf: 'global',
      language: 'German',
      language_sf: 'de',
      available_local: 'de',
    },
    {
      locale: 'global-ar',
      region: 'Global',
      region_sf: 'global',
      language: 'Arabic',
      language_sf: 'ar',
      available_local: 'ar',
    },
    {
      locale: 'global-ru',
      region: 'Global',
      region_sf: 'global',
      language: 'Russion',
      language_sf: 'ru',
      available_local: 'ru',
    },
    {
      locale: 'global-es',
      region: 'Global',
      region_sf: 'global',
      language: 'Spanish',
      language_sf: 'es',
      available_local: 'es',
    },

    {
      locale: 'global-fr',
      region: 'Global',
      region_sf: 'global',
      language: 'French',
      language_sf: 'fr',
      available_local: 'fr',
    },

    {
      locale: 'global-zh',
      region: 'Global',
      region_sf: 'global',
      language: 'Chinese - Simplified',
      language_sf: 'zh',
      available_local: 'zh',
    },
  ],
  nonRegionalized: ['sadhguru'],

  env: {
    local: 1,
    development: 2,
    qa: 3,
    uat: 4,
    staging: 5,
    production: 6,
  },

  monthMap: {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'July',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
  },
  RHSMenu: [
    {
      text: 'Volunteer',
      url: 'https://isha.sadhguru.org/volunteer',
    },
    {
      text: 'Donate',
      url: `https://isha.sadhguru.org/in/en/donation`,
    },
    {
      text: 'Shop',
      url: 'https://ishalife.com/',
    },
  ],
  USMenu: [
    {
      text: 'Program Finder',
      url: '/us/en/program-finder-us',
    },
  ],

  indianLang: ['ta', 'te', 'hi', 'mr', 'kn', 'es', 'bn', 'ml', 'or'],
  allLang: ['ta', 'te', 'hi', 'mr', 'kn', 'es', 'de', 'fr', 'ru', 'zh'],
  lngList: {
    en: 'English',
    ta: 'Tamil',
    hi: 'Hindi',
    te: 'Telugu',
    ml: 'Malayalam',
    kn: 'Kannada',
    mr: 'Marathi',
    gu: 'Gujarati',
    es: 'Spanish',
    pt: 'Portuguese',
    ne: 'Nepali',
    fr: 'French',
    ru: 'Russian',
    bn: 'Bengali',
    or: 'Oriya',
  },
  lngListTrans: {
    en: 'English',
    ta: 'தமிழ்',
    hi: 'हिंदी',
    te: 'తెలుగు',
    ml: 'മലയാളം',
    kn: 'ಕನ್ನಡ',
    mr: 'मराठी',
    gu: 'Gujarati',
    or: 'ଓଡିଆ',
    es: 'español',
    pt: 'Portuguese',
    ne: 'Nepali',
    fr: 'Français',
    ru: 'Pусский',
    bn: 'বাংলা',
    zh: '简体中文',
    'zh-tw': '繁體中文',
    de: 'Deutsch',
    it: 'Italiano',
    ar: 'عربي',
    id: 'Indonesian',
  },
  orderedLangList: {
    en: ['en', 'hi', 'ta', 'kn', 'te', 'mr', 'ml', 'bn'],
    hi: ['hi', 'mr', 'bn', 'en'],
    ta: ['ta', 'en'],
    kn: ['kn', 'en'],
    te: ['te', 'en'],
    mr: ['mr', 'hi', 'en'],
    ml: ['ml', 'en'],
    bn: ['en', 'hi', 'bn'],
  },
  orderedLang: ['en', 'hi', 'ta', 'kn', 'te', 'mr', 'ml', 'bn'],
  langFont: {
    en: "'FedraSansStd-book', sans-serif",
    ta: "'Anek Tamil', sans-serif",
    hi: "'Anek Devanagari', sans-serif",
    te: "'Anek Telugu', sans-serif",
    ml: "'Anek Malayalam', sans-serif",
    kn: "'Anek Kannada', sans-serif",
    mr: "'Anek Devanagari', sans-serif",
    gu: "'Anek Gujarati', sans-serif",
    es: "'FedraSansStd-book', sans-serif",
    pt: "'FedraSansStd-book', sans-serif",
    ne: "'Anek Devanagari', sans-serif",
    fr: "'FedraSansStd-book', sans-serif",
    ru: "'FedraSansStd-book', sans-serif",
    bn: "'Anek Bangla', sans-serif",
    zh: "'Noto Sans TC', sans-serif",
    'zh-tw': "'Noto Sans TC', sans-serif",
    de: "'FedraSansStd-book', sans-serif",
    it: "'FedraSansStd-book', sans-serif",
    id: "'FedraSansStd-book', sans-serif",
    ar: "'Almarai', sans-serif",
  },

  isoLangFont: {
    en: "'FedraSansStd-book', sans-serif",
    ta: "'MuktaMalar', sans-serif",
    hi: "'Mukta', sans-serif",
    te: "'Anek Telugu', sans-serif",
    ml: "'Anek Malayalam', sans-serif",
    kn: "'Anek Kannada', sans-serif",
    mr: "'Anek Devanagari', sans-serif",
    gu: "'Anek Gujarati', sans-serif",
    es: "'FedraSansStd-book', sans-serif",
    pt: "'FedraSansStd-book', sans-serif",
    ne: "'Anek Devanagari', sans-serif",
    fr: "'FedraSansStd-book', sans-serif",
    ru: "'FedraSansStd-book', sans-serif",
    bn: "'Anek Bangla', sans-serif",
    zh: "'Noto Sans TC', sans-serif",
    'zh-tw': "'Noto Sans TC', sans-serif",
    de: "'FedraSansStd-book', sans-serif",
    it: "'FedraSansStd-book', sans-serif",
    id: "'FedraSansStd-book', sans-serif",
    ar: "'Almarai', sans-serif",
  },

  regionArray: [
    { name: 'Australia', reg: 'au' },
    { name: 'Canada', reg: 'ca' },
    { name: 'India', reg: 'in' },
    { name: 'Malaysia', reg: 'my' },
    { name: 'Singapore', reg: 'sg' },
    { name: 'UK & Europe', reg: 'uk' },
    { name: 'United States', reg: 'us' },
    { name: 'Global', reg: 'global' },
  ],

  // These are the language options for each locale, please add them in the alphabetical order
  languageOptionsDirectory: {
    in: [
      {
        language: 'Tamil',
        region: 'in',
        lang: 'ta',
        link: 'https://isha.sadhguru.org/in/ta/events/monthly/sadhguru-satsang',
      },
      {
        language: 'Hindi',
        region: 'in',
        lang: 'hi',
        link: 'https://isha.sadhguru.org/in/hi/events/monthly/sadhguru-satsang',
      },
      {
        language: 'Telugu',
        region: 'in',
        lang: 'te',
        link: 'https://isha.sadhguru.org/in/te/events/monthly/sadhguru-satsang',
      },
      {
        language: 'Kannada',
        region: 'in',
        lang: 'kn',
        link: 'https://isha.sadhguru.org/in/kn/events/monthly/sadhguru-satsang',
      },
      {
        language: 'Malayalam',
        region: 'in',
        lang: 'ml',
        link: 'https://isha.sadhguru.org/in/ml/events/monthly/sadhguru-satsang',
      },
      {
        language: 'Marathi',
        region: 'in',
        lang: 'mr',
        link: 'https://isha.sadhguru.org/in/mr/events/monthly/sadhguru-satsang',
      },
    ],
    global: [
      // {
      //   "language": "Arabic",
      //   "link": "https://isha.sadhguru.org/global/ar/events/monthly/sadhguru-satsang"
      // },
      {
        language: 'French',
        region: 'global',
        lang: 'fr',
        link: 'https://isha.sadhguru.org/global/fr/events/monthly/sadhguru-satsang',
      },
      {
        language: 'German',
        region: 'global',
        lang: 'de',
        link: 'https://isha.sadhguru.org/global/de/events/monthly/sadhguru-satsang',
      },
      {
        language: 'Russian',
        region: 'global',
        lang: 'ru',
        link: 'https://isha.sadhguru.org/global/ru/events/monthly/sadhguru-satsang',
      },
      {
        language: 'Spanish',
        region: 'global',
        lang: 'es',
        link: 'https://isha.sadhguru.org/global/es/events/monthly/sadhguru-satsang',
      },
      {
        language: 'Chinese - Simplified',
        region: 'global',
        lang: 'zh',
        link: 'https://isha.sadhguru.org/global/zh/events/monthly/sadhguru-satsang',
      },
      {
        language: 'Arabic',
        region: 'global',
        lang: 'ar',
        link: 'https://isha.sadhguru.org/global/ar/events/monthly/sadhguru-satsang',
      },
    ],
    uk: [
      {
        language: 'French',
        region: 'global',
        lang: 'fr',
        link: 'https://isha.sadhguru.org/global/fr/events/monthly/sadhguru-satsang',
      },
      {
        language: 'German',
        region: 'global',
        lang: 'de',
        link: 'https://isha.sadhguru.org/global/de/events/monthly/sadhguru-satsang',
      },
      {
        language: 'Spanish',
        region: 'global',
        lang: 'es',
        link: 'https://isha.sadhguru.org/global/es/events/monthly/sadhguru-satsang',
      },
      {
        language: 'Russian',
        region: 'global',
        lang: 'ru',
        link: 'https://isha.sadhguru.org/global/ru/events/monthly/sadhguru-satsang',
      },
    ],
  },
  msrlanguage: [
    {
      region_sf: 'IN',
      locale: 'en_IN',
      language_sf: '/mahashivratri',
      language: 'English',
    },
    {
      region_sf: 'IN',
      locale: 'ta_IN',
      language_sf: 'ta',
      language: 'தமிழ்',
    },
    {
      region_sf: 'IN',
      locale: 'hi_IN',
      language_sf: 'hi',
      language: 'हिंदी',
    },
    {
      region_sf: 'IN',
      locale: 'kn_IN',
      language_sf: 'kn',
      language: 'ಕನ್ನಡ',
    },
    {
      region_sf: 'IN',
      locale: 'te_IN',
      language_sf: 'te',
      language: 'తెలుగు',
    },
    {
      region_sf: 'IN',
      locale: 'mr_IN',
      language_sf: 'mr',
      language: 'मराठी',
    },
    {
      region_sf: 'IN',
      locale: 'or_IN',
      language_sf: 'or',
      language: 'ଓଡିଆ',
    },
    {
      region_sf: 'IN',
      locale: 'ml_IN',
      language_sf: 'ml',
      language: 'മലയാളം',
    },
    {
      region_sf: 'IN',
      locale: 'gu_IN',
      language_sf: 'gu',
      language: 'ગુજરાતી',
    },
    { region_sf: '', locale: 'de', language_sf: 'de', language: 'Deutsch' },
    { region_sf: '', locale: 'it', language_sf: 'it', language: 'Italiano' },
    { region_sf: '', locale: 'es', language_sf: 'es', language: 'Española' },
    { region_sf: '', locale: 'fr', language_sf: 'fr', language: 'Français' },
    { region_sf: '', locale: 'ru', language_sf: 'ru', language: 'Русский' },
    { region_sf: '', locale: 'ne', language_sf: 'ne', language: 'नेपाली' },
  ],

  currencylist: [
    {
      currency: 'GBP',
      symbol: '£',
    },
  ],
  // Add timezones in the ISO format
  timezones: [
    '2021-06-24T13:30:00Z',
    '2021-06-24T17:00:00Z',
    '2021-06-24T23:00:00Z',
    '2021-06-25T02:00:00Z',
  ],
  imgPath: 'https://static.sadhguru.org',
  cdnPath: 'https://isha.sadhguru.org',
  staticPath: 'https://static.sadhguru.org',
  actualSatsangDate: '',

  middleEastCountries: [
    'AE',
    'KW',
    'QA',
    'SA',
    'BH',
    'JO',
    'OM',
    'AF',
    'PK',
    'TR',
    'IQ',
    'IR',
    'LB',
    'PS',
    'SY',
    'YE',
    'EG',
    'AU',
    'KP',
    'KR',
    'SG',
    'BN',
    'KH',
    'LA',
    'MM',
    'MN',
    'MY',
    'PG',
    'VN',
    'CX',
    'CC',
    'CK',
    'FJ',
    'PF',
    'HM',
    'KI',
    'NR',
    'NC',
    'NU',
    'NF',
    'PN',
    'WS',
    'SB',
    'TW',
    'TL',
    'TK',
    'TO',
    'TV',
    'VU',
    'WF',
    'NZ',
    'BD',
    'BT',
    'LK',
    'CN',
    'HK',
    'JP',
    'MO',
    'ID',
    'TH',
    'PH',
  ],

  development: {
    menu: 'https://dev-phase2-facade.sadhguru.org/content/fetchiso/menu?format=json&locale=',
    footer:
      'https://dev-phase2-facade.sadhguru.org/content/fetchiso/footer?format=json&locale=',
    content:
      'https://dev-phase2-facade.sadhguru.org/content/fetchiso/content?format=json&slug=',
  },
  qa: {
    menu: 'https://qa-phase2-facade.sadhguru.org/content/fetchiso/menu?format=json&locale=',
    footer:
      'https://qa-phase2-facade.sadhguru.org/content/fetchiso/footer?format=json&locale=',
    content:
      'https://qa-phase2-facade.sadhguru.org/content/fetchiso/content?format=json&slug=',
  },
  uat: {
    menu: 'https://uat-iso-facade.sadhguru.org/content/fetchiso/menu?format=json&locale=',
    footer:
      'https://uat-iso-facade.sadhguru.org/content/fetchiso/footer?format=json&locale=',
    content:
      'https://uat-iso-facade.sadhguru.org/content/fetchiso/content?format=json&locale=',
  },
  production: {
    menu: 'https://iso-facade.sadhguru.org/content/fetchiso/menu?format=json&local`e=',
    footer:
      'https://iso-facade.sadhguru.org/content/fetchiso/footer?format=json&locale=',
    content:
      'https://iso-facade.sadhguru.org/content/fetchiso/content?format=json&locale=',
  },
  currentEnv: 'development',
};

// Build configs Starts
const local = localConfig;
const development = developmentConfig;
const qa = qaConfig;
const development_home = development_homeConfig;
const stage_home = stage_homeConfig;
const staging = stagingConfig;
const stage_v2 = stage_v2Config;
const uat = uatConfig;
const uat_home = uat_homeConfig;
const production = productionConfig;
const production_home = production_homeConfig;
// Build Configs Ends

const node_env = process.env.NEXT_PUBLIC_NODE_ENV || 'local';
const config = {
  local,
  development,
  qa,
  development_home,
  production,
  production_home,
  uat,
  uat_home,
  stage_v2,
  staging,
  stage_home,
};

console.log(
  `ISO v4.1.1- ${node_env} -- APP MODE=>${
    process.env.NEXT_PUBLIC_BUILDTYPE
  } -- ENV=>${process.env.NEXT_PUBLIC_NODE_ENV} -- BUILD_ID=>${
    process.env.NEXT_PUBLIC_BUILD_ID
  } ISO SSR_MODE=>${process.env.SSR_MODE} || SSR_MODE=> ${
    process.env.NEXT_PUBLIC_SSR_MODE
  } <-> ${process.env.BUILD_COMMIT_ID} on ${new Date()}`
);

export default { ...config[node_env], ...generalConfig };
