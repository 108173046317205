/* Built In Imports */
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

/* External Imports */
import { Box, Button, Flex, Link, Text } from '@chakra-ui/react';
import { FaAngleDown } from 'react-icons/fa';

/* Internal Imports */
/* Components */
import config from '@config';
import CountriesList from '@constants/CountriesList';
import { AuthContext } from '@store/auth-context';

/* Services */
import { getCurrentRegion } from 'services/commonService';

/**
 *
 * @returns
 */
const RegionSwitcher = () => {
  const [selectedRegion, setSelectedRegion] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [langIsOpen, setLangIsOpen] = useState(false);
  const authContext = useContext(AuthContext);
  const router = useRouter();
  const { region, language } = router.query;

  const localData = async () => {
    const reg = await getCurrentRegion();

    if (reg?.country) {
      const ipRegion = CountriesList.filter(item => {
        return item.value.toLowerCase() === reg.country.toLowerCase();
      });
      if (ipRegion?.length && ipRegion[0].reg) {
        // console.log('eg?.country', ipRegion[0].reg !== region, ipRegion[0].reg, region);
        authContext.RegionAndLang(ipRegion[0].reg, language);
        setIsVisible(ipRegion[0].reg !== region);
        if (ipRegion[0].reg !== region){
          dataLayerPushOnload();
        }
      } else {
        // console.log('setIsVisible false', ipRegion[0].reg, region);
        setIsVisible(false);
      }

      const urlRegion =
        region &&
        config?.regionArray.filter(item => {
          return item.reg.toLowerCase() === ipRegion[0].reg.toLowerCase();
        });
      // console.log('urlRegion false', urlRegion, ipRegion[0].reg);
      if (urlRegion?.length) {
        setSelectedRegion(urlRegion[0]);
      }
    }
  };

  useEffect(() => {
    // console.log('isVisible', isVisible);
    localData();
  }, [router.asPath]);

  const continueRegion = () => {
    dataLayerPush(ipRegion[0].reg,selectedRegion?.reg);
    router.push(
      router.asPath.replace(`/${region}/`, `/${selectedRegion?.reg}/`)
    );
  };

  const dataLayerPush = (ipc,cunt) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'region_switch_clicked',
      ip_country:ipc,
      country:cunt,
    });
  };

  const dataLayerPushOnload = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'region_switch_message_viewed',
    });
  };

  return (
    isVisible && (
      <Box bg="#02026C">
        <Box
          maxW="1170px"
          px="15px"
          w="full"
          mx="auto"
          py={{ base: '12px', md: '20px' }}
          alignItems={{ md: 'center' }}
          justifyContent="space-between"
          flexDir={{ base: 'column', md: 'row' }}
          display="flex"
          gridGap="16px"
        >
          <Text color="white" fontFamily="FedraSansStd-book">
            Choose another country or region to see content specific to your
            location
          </Text>
          <Box display="flex" gridGap="16px" justifyContent="flex-end">
            <Button
              fontFamily="FedraSansStd-medium"
              bg="#02026C"
              border="1px solid #fff"
              fontWeight="normal"
              onClick={() => setLangIsOpen(!langIsOpen)}
            >
              {' '}
              {selectedRegion?.name}{' '}
              <Box pl="5px">
                <FaAngleDown />
              </Box>
              {langIsOpen && (
                <Flex
                  className="options"
                  alignItems="flex-start"
                  alignContent="flex-start"
                  position="absolute"
                  top={{ base: '48px' }}
                  zIndex="100"
                  w="100%"
                  minW="130px"
                  left="0"
                  boxShadow="0px 0.75926px 2.36111px 0px rgba(0, 0, 0, 0.03), 0px 3.34074px 4.88889px 0px rgba(0, 0, 0, 0.05), 0px 8.2px 9.75px 0px rgba(0, 0, 0, 0.06), 0px 15.79259px 19.11111px 0px rgba(0, 0, 0, 0.07), 0px 26.57407px 35.13889px 0px rgba(0, 0, 0, 0.09), 0px 41px 60px 0px rgba(0, 0, 0, 0.12)"
                  flexDir="column"
                  bg="#ffffff"
                  mt={{ base: '0', md: '0' }}
                  p={{ base: '10px 15px', md: '10px 15px' }}
                >
                  {config?.regionArray?.map((regionItem, index) => {
                    return (
                      <Link
                        key={index}
                        color="#6D6358"
                        fontFamily="FedraSansStd-book"
                        textAlign="left"
                        fontSize={'16px'}
                        _hover={{
                          color: '#F37021',
                        }}
                        onClick={() => setSelectedRegion(regionItem)}
                        my={2}
                      >
                        {regionItem.name}
                      </Link>
                    );
                  })}
                </Flex>
              )}
            </Button>
            <Button
              fontFamily="FedraSansStd-medium"
              border="1px solid transparent"
              fontWeight="normal"
              onClick={continueRegion}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
    )
  );
};

export default RegionSwitcher;
